<template>
    <div class="page">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm"
                 @keyup.enter.native="search()"
                 label-width="100px">
            <el-form-item prop="name" label="输入搜索：">
                <el-input v-model.trim="searchForm.name" maxlength="50" placeholder="请输入模板名称/简介搜索"
                          clearable></el-input>
            </el-form-item>
            <el-form-item prop="basicId" label="元数据：">
                <el-select v-model="searchForm.basicId" placeholder="请选择" filterable>
                    <el-option v-for="item in archivesBasicOpt" :key="item.archivesBasicId" :label="item.basicName"
                               :value="item.archivesBasicId"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="search()" size="small">查询</el-button>
                <el-button @click="reset()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
            <el-form-item class="f_r p_r2">
                <el-button type="primary" icon="el-icon-plus" size="small" v-show="hasPermissionButton('collection:templateList:add')"
                           @click="addEdit(null, 'add')">新增
                </el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white" style="height: calc(100% - 74px);">
            <vxe-table
                resizable
                ref="xTree"
                border="inner"
                auto-resize
                height="auto"
                row-id="id"
                size="small"
                show-header-overflow
                show-overflow
                highlight-hover-row
                :print-config="{}"
                :export-config="{}"
                :tree-config="{}"
                v-loading="loading"
                :checkbox-config="{checkStrictly: true}"
                :data="dataList">
                <vxe-table-column title="模板名称" field="archivesBasicName" tree-node></vxe-table-column>
                <vxe-table-column title="简介" field="remakes"></vxe-table-column>
                <vxe-table-column title="操作" width="150px" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" v-show="hasPermissionButton('collection:templateList:view')"
                                   @click="addEdit(scope.row, 'view')">详情
                        </el-button>
                        <el-button type="text" size="small" v-show="hasPermissionButton('collection:templateList:edit')"
                                   @click="addEdit(scope.row, 'edit')">修改
                        </el-button>
                        <el-button type="text" size="small" v-show="hasPermissionButton('collection:templateList:delete')"
                                   @click="del(scope.row.id)">删除
                        </el-button>
                    </template>
                </vxe-table-column>
            </vxe-table>
        </div>
        <TemplateListForm ref="templateListForm" @refreshDataList="search()"></TemplateListForm>
    </div>
</template>

<script>
import TemplateListForm from './templateListForm'
import XEUtils from 'xe-utils'

export default {
    components: {
        TemplateListForm
    },
    data() {
        return {
            searchForm: {
                name: '',
                basicId: '',
            },
            archivesBasicOpt: [],
            loading: false,
            dataList: [],
        }
    },
    mounted() {
        this.getArchivesBasicOpt()
        this.search()
    },
    methods: {
        // 查询
        search() {
            this.loading = true
            this.$axios(this.api.collection.selectArchivesBasicData, {
                basicId: this.searchForm.basicId
            }, 'post').then((res) => {
                if (res.status) {
                    this.dataList = res.data
                    this.filterSearch()
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
            })
        },
        // 获取元数据名称选项
        getArchivesBasicOpt() {
            this.$axios(this.api.collection.selectAllArchivesBasic).then((res) => {
                if (res.status) {
                    this.archivesBasicOpt = res.data
                }
            })
        },
        // 过滤
        filterSearch() {
            if (this.searchForm.name) {
                let options = {children: 'children'}
                let searchProps = ['archivesBasicName', 'remakes']
                this.dataList = XEUtils.searchTree(this.dataList, item => searchProps.some(key => XEUtils.toValueString(item[key]).indexOf(this.searchForm.name) > -1), options)
                this.$nextTick(() => {
                    this.$refs.xTree.setAllTreeExpand(true)
                })
            }
        },
        // 新增
        addEdit(row, method) {
            this.$refs.templateListForm.init(row, method)
        },
        // 删除
        del(id) {
            // 校验是否被使用
            this.$axios(this.api.collection.tepCheckRemoveById + '/' + id, {}, 'delete').then(data => {
                if (!data.status) {
                    const h = this.$createElement;
                    this.$msgbox({
                        title: '提示',
                        message: h('p', null, [
                            h('p', {style: 'color: #000000; margin-bottom: 5px'}, '不支持删除元数据模板，已关联档案数据！'),
                            h('p', {style: 'color: #999999; font-size: 12px'}, '删除元数据模板，关联档案数据将同步删除。')
                        ]),
                        showCancelButton: true,
                        confirmButtonText: '坚持删除',
                        cancelButtonText: '取消',
                    }).then(() => {
                        this.delData(id)
                    });
                } else {
                    this.$confirm(`确定删除所选项吗?`, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.delData(id)
                    })
                }
            })
        },
        // 删除数据
        delData(id) {
            this.$axios(this.api.collection.basicdataRemoveById + '/' + id, {}, 'delete').then(data => {
                if (data.status) {
                    this.$message.success('删除成功')
                    this.search()
                } else {
                    this.$message.error(data.msg)
                }
            })
        },
        //重置
        reset() {
            this.$refs.searchForm.resetFields();
            this.search()
        },
    }
}
</script>

<style scoped>

</style>
